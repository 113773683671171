import TableToolbar from '@/components/TableToolbar.vue'
import ApprovalTable from '@/components/ApprovalTable.vue'

export default {
  name: 'List',
  components: { ApprovalTable, TableToolbar },
  data() {
    return {}
  }
}
